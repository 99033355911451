<template>
  <div class="login-main">
    <div class="ape-content">
      <div class="ape-top">
        <div class="ape-header">
            <router-link to="/">
            <img alt="logo" class="ape-logo" :src="logo" />
            </router-link>
        </div>
      </div>
    </div>
    <div class="auth-main">
      <el-tabs v-model="activeName" :before-leave="beforeSwitch">
        <el-tab-pane  name="login2" style="margin: auto; width : 20vw;">
          <el-form ref="loginForm" :model="loginData" :rules="rules" >
            <el-form-item prop="username">
              <el-input
                v-model="loginData.username"
                placeholder="账号"
                prefix-icon="iconfont icon-shouji4"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                :type="lockPassword"
                v-model="loginData.password"
                placeholder="密码"
              ><i slot="prefix" class="el-input__icon el-icon-lock" @click="changeLockPassword"></i></el-input>

            </el-form-item>
            <el-form-item prop="authcode">
              <el-input
                :type="lockAuthCode"
                v-model="loginData.authcode"
                maxlength="6"
                placeholder="二次密码"
              ><i slot="prefix" class="el-input__icon el-icon-lock" @click="changeLockAuthCode"></i></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="login-button" type="primary" @click="loginClick"
                >登 录</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="loginData.remember">记住密码</el-checkbox>
              <router-link
                class="login-register"
                to="login"
                @click.native="registerClick"
                >找回密码</router-link
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
       
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import logo from "@/assets/logo.svg";
import { encrypt } from '@/utils/utils'
export default {
  data() {
    return {
      lockPassword:"password",
      lockAuthCode:"password",
      activeName: "login2",
      loginData: {},
      logo,
      // 表单验证
      rules: {
        username: [{ required: true, message: "输入账号", trigger: "blur" }],
        password: [{ pattern:/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){8,20}$/,required: true, message: '密码必须输入8-20且字母、数字和标点符号至少包含两种', trigger: 'blur' }],
        authcode: [{max:6,min:6,required: true, message: "输入6位二次密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    ...mapMutations(["handleUserInfo"]),
    registerClick() {
      this.$message.error("功能暂未开放。");
    },
    // tab标签切换前调用,activeName, oldActiveNam
    beforeSwitch(active) {  
      console.log(active)
    },
    changeLockPassword() {
      this.lockPassword = this.lockPassword === 'password' ? 'text' : 'password'
    },
    changeLockAuthCode() {
      this.lockAuthCode = this.lockAuthCode === 'password' ? 'text' : 'password'
    },
    Encrypt(){
      return encrypt(this.loginData.password)
    },
    // 点击登录按钮
    loginClick() {
      // 调用组件的数据验证方法
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.formSubmit();
        } else {
          this.$message.error("登录失败，请检查必填项数据！");
        }
      });
    },
    // 实际登录请求
    async formSubmit() {
      let data = Object.assign({},this.loginData);
      data.password = this.Encrypt()
      let userInfo = await this.$api.Login(data);
      if (userInfo.length == 0) {
        this.$message.error("登录失败，请联系管理员！");
        return;
      }
      if (userInfo) {
        localStorage.setItem("user_info", JSON.stringify(userInfo));
        this.handleUserInfo(userInfo);
        this.$message.success("登录成功！");
        this.go();
      } else {
        this.initRespErrMsgBoxMark();
        this.$message.error("登录失败，请联系管理员！");
      }
    },
    // 路由跳转
    go(url = "/") {
      this.$router.push(url);
    },
    // 初始化响应错误弹窗标志
    initRespErrMsgBoxMark() {
      this.$store.commit("changeRespErrMsgBoxMark", false);
    },
  },
  mounted() {
    this.initRespErrMsgBoxMark();
  },
};
</script>

<style lang="stylus">

.login-main {
  padding: 0;
  background-size: cover;
  width : 26vw; 
  margin: 0 auto;
  margin-top: 15vh; 
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.7);
  position: relative;
    
  .auth-main {
    width : 26vw; 
    padding-bottom:  10vh; 
  }

  .el-tabs__nav-wrap::after {
    height: 0px;
  }

  .el-tabs__nav {
    display: inline-block;
    float: none;
  }

  .el-tabs__header {
    padding-top: 3vh;
    text-align: center;
  }
  .el-input__icon {
    line-height: 100%;
  }
  .el-input__prefix {
    left: 8px;
  }

  .el-tabs__header {
    margin-bottom: 24px;
  }

  .login-button {
    width: 100%;
  }

  .login-register {
    float: right;
    text-decoration: none;
    color: #1890ff;
  }

  .el-checkbox, .el-checkbox__input.is-checked + .el-checkbox__label {
    color: rgba(0, 0, 0, 0.65);
  }

  #tab-login2 {
    height: 0px;
  }
}
</style>
